$(function(){
    /* validation */
    function submitBtnAccess(element) {
        const submitButton = element.closest('form').find('.js-submit-button')
        const requiredFields = element.closest('form').find('.is-required')
        const cbRrequired = element.closest('form').find('.cb-required')

		var reqCount = requiredFields.length,
			reqCountValid = 0;
		requiredFields.each(function(){
            const control = $(this).closest('.form-element')
			if (control.hasClass('is-ok')) {
				reqCountValid++;
			}
		})
		if (reqCountValid == reqCount) {
            if (cbRrequired.length) {
                if (cbRrequired.prop('checked')) {
                    submitButton.removeClass('is-disabled');    
                }
            }
            else {
                submitButton.removeClass('is-disabled');
            }
		}
		else {
			submitButton.addClass('is-disabled');
		}
	}

    $('.js-email-input').on('input', function(){
        const submit = $(this).closest('form').find('[type="submit"]')
        submit.addClass('is-disabled')
        if ( validateEmail($(this).val()) ) submit.removeClass('is-disabled')
    })

    function validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }
    function emailValid(element) {
        const control = element.closest('.form-element')
        const messageArea = control.find('.js-error-message')
        const email = element.val().trim()

        if (email == '') {
            control.addClass('is-error').removeClass('is-ok');
            messageArea.text('*Заполните поле')
        }
        else if (validateEmail(email)) {
            control.removeClass('is-error').addClass('is-ok');
            messageArea.text('')
        }
        else {
            control.addClass('is-error').removeClass('is-ok');
            messageArea.text('*Неверный формат')
        }
    }
    function commonValid(element) {
        const val = element.val().trim()
        const control = element.closest('.form-element')
        const messageArea = control.find('.js-error-message')

		if (val == '') {
            control.addClass('is-error').removeClass('is-ok');
            messageArea.text('*Заполните поле')
		}
		else {
            control.removeClass('is-error').addClass('is-ok');
            messageArea.text('')
		}
	}
    function phoneValid(element) {
        const val = element.val().trim()
        const control = element.closest('.form-element')
        const messageArea = control.find('.js-error-message')

		if (val == '') {
            control.addClass('is-error').removeClass('is-ok');
            messageArea.text('*Заполните поле')
		}
        else if (val.length < 16) {
            control.addClass('is-error').removeClass('is-ok');
            messageArea.text('*Неверный формат')
        }
		else {
            control.removeClass('is-error').addClass('is-ok');
            messageArea.text('')
		}
	}
    function dateValid(element) {
        const val = element.val().trim()
        const control = element.closest('.form-element')
        const messageArea = control.find('.js-error-message')

		if (val == '') {
            control.addClass('is-error').removeClass('is-ok');
            messageArea.text('*Заполните поле')
		}
        else if (val.length < 10) {
            control.addClass('is-error').removeClass('is-ok');
            messageArea.text('*Неверный формат')
        }
		else {
            control.removeClass('is-error').addClass('is-ok');
            messageArea.text('')
		}
	}
    function donateValid(element) {
        const minVal = Number(element.attr('min'))
        const val = element.val().trim()
        const control = element.closest('.form-element')
        const messageArea = control.find('.js-error-message')

		if (val != '' && Number(val) < 10) {
            control.addClass('is-error').removeClass('is-ok');
            messageArea.text(`Минимальная сумму пожертвования – ${minVal} рублей`)
		}
		else {
            control.removeClass('is-error')
            messageArea.text('')
		}
	}
    function radioValid(element) {
        const control = element.closest('.form-element')
        const messageArea = control.find('.js-error-message')

        let checkedCount = 0
        $(`[name="${element.attr('name')}"]`).each(function(){
            if ($(this).prop('checked')) checkedCount++
        })
		if (checkedCount == 0) {
            control.addClass('is-error').removeClass('is-ok');
            messageArea.text('*Заполните поле')
		}
		else {
            control.removeClass('is-error').addClass('is-ok');
            messageArea.text('')
		}
	}
    function InitInputDadata() {
        $("input.js-dadata").each(function () {
            let input = $(this);
            let inputType = input.data("dadata-type");
            if ((!input.hasClass("dadated"))) {
                input.suggestions({
                    token: "64e71475d3ee6ef29f4526d74f5969bce84dda05",
                    type: inputType,
                    onSelect: function(suggestion) {
                        // console.log(suggestion);
                        if (input.hasClass('js-email')) {
                            emailValid(input)
                            submitBtnAccess( $(input) )
                        }
                    }
                });
                input.addClass("dadated");
            }
        });
    }
    InitInputDadata()

    $(document).on('input', '.is-required', function(){
        const field = $(this)

        if (field.hasClass('js-email')) {
            emailValid(field)
        }
        else if (field.hasClass('js-phone')) {
            phoneValid(field)
        }
        else if (field.hasClass('js-date')) {
            dateValid(field)
        }
        else if (field.attr('type') == 'radio') {
            radioValid(field)
        }
        else {
            commonValid(field)
        }

        submitBtnAccess(field)
    })
    $('.js-donate-amount').on('input', function(){
        donateValid($(this))
    })
    $('.js-donate-amount').on('focus', function(){
        $('.donation-preset input').prop('checked', false)
    })
    $('.cb-required').on('change', function(){
        const cb = $(this)
        const reqFields = cb.closest('form').find('.is-required')
        if (cb.prop('checked')) {
            reqFields.each(function(){
                submitBtnAccess($(this))
            })
        }
        else {
            cb.closest('form').find('.js-submit-button').addClass('is-disabled')
        }        
    })
    $('.donation-preset input').on('change', function(){
        $('.js-donate-amount').val('')
        $('.js-donate-amount').parent().find('.js-error-message').text('')
        $('.js-donate-amount').closest('.form-element').removeClass('is-error')


    })
    /* validation */

    /* submit logic */
    $(document).on('submit', '.js-form', function(e){
        e.preventDefault()
        const form = $(this)
        const isVolunteerForm = form.hasClass('form-volunteer')
        const errorMessageTarget = isVolunteerForm ? form.find('.form-submit-area__btn-wrapper') : form.find('.js-form-fields')
        const submitButton = form.find('.js-submit-button')
        const submitButtonParent = submitButton.parent()
        const loaderHTML = '<div class="load-anim-area"><div class="load-anim"></div></div>'
        const formContent = form.find('.form__content')
        const successMessageHTML = '<div class="form__sent-message">Спасибо за обращение!<br>Мы с Вами свяжемся в ближайшее время.</div>'
        const errorMessageHTML = '<div class="form__error-message js-send-error-message">При отправке данных произошла ошибка.<br>Попробуйте снова</div>'

        if (submitButton.hasClass('is-disabled')) return;

        console.log('go send')
        $('.js-send-error-message').remove()
        const formData = new FormData(form[0])
        let formObj = isVolunteerForm ? [] : {}
        for(var pair of formData.entries()) {
            if (pair[1] !== '') {
                if (isVolunteerForm) {
                    const data = {}
                    data[pair[0]] = pair[1]
                    formObj.push(data)
                }
                else {
                    formObj[pair[0]] = pair[1]
                }
            }            
        }
        console.log(formObj)
        submitButtonParent.append(loaderHTML)
        submitButton.addClass('is-disabled')

        // server job simulation
        const promise = new Promise((resolve, reject) => {
            setTimeout(() => resolve(), 2000);
            // setTimeout(() => reject(new Error()), 1000);
        });    
        promise.then(
            function(result) {
                console.log('data was sent')
                form.prepend(successMessageHTML)
                formContent.hide()
            },
            function(error) {
                console.log('data wasnt sent')
                submitButtonParent.find('.load-anim-area').remove()
                submitButton.removeClass('is-disabled')
                errorMessageTarget.append(errorMessageHTML)
            }
        );
    })
    /* submit logic */

    /*donation form submit logic */
    $(document).on('submit', '.js-form-donate', function(e){
        e.preventDefault()
        const form = $(this)
        if (!form.find('.donation-preset input:checked').length) {
            if (Number(form.find('.js-donate-amount').val()) < Number(form.find('.js-donate-amount').attr('min'))) {
                alert('Введите сумму пожертвования')
                return
            }
        }

        const errorMessageTarget = form.find('.form-submit-wrapper')
        const submitButton = form.find('.js-submit-button')
        const submitButtonParent = submitButton.parent()
        const loaderHTML = '<div class="load-anim-area"><div class="load-anim"></div></div>'
        const errorMessageHTML = '<div class="form__error-message js-send-error-message">При отправке данных произошла ошибка.<br>Попробуйте снова</div>'

        if (submitButton.hasClass('is-disabled')) return;

        console.log('go send')
        $('.js-send-error-message').remove()
        const formData = new FormData(form[0])
        let formObj = {}
        for(var pair of formData.entries()) {
            if (pair[1] !== '') {
                formObj[pair[0]] = pair[1]
            }            
        }
        console.log(formObj)
        submitButtonParent.append(loaderHTML)
        submitButton.addClass('is-disabled')

        // server job simulation
        const promise = new Promise((resolve, reject) => {
            setTimeout(() => resolve(), 2000);
            // setTimeout(() => reject(new Error()), 1000);
        });    
        promise.then(
            function(result) {
                // тут должна запускаться модалка cloudpayments
                console.log('open cloudpayments window')
                submitButtonParent.find('.load-anim-area').remove()
                submitButton.removeClass('is-disabled')
            },
            function(error) {
                console.log('data wasnt sent')
                submitButtonParent.find('.load-anim-area').remove()
                submitButton.removeClass('is-disabled')
                errorMessageTarget.append(errorMessageHTML)
            }
        );
    })
    /*donation form submit logic */
})